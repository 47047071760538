import React from "react";
import AppLayout from "./layout";
import { useHistory } from "react-router-dom";
import { set } from "ramda";
import queryString from "query-string";

export default function Idle() {
  
  const history = useHistory();
  const parsed = queryString.parse(history.location.search);
  console.log(parsed)

  React.useEffect(() => {

    const timeout = setTimeout(() => {
      // 👇️ redirects to an external URL
      window.location.replace("/de/home");
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);


  return (
    <AppLayout title={""} style={{ padding: 0 }}>
      <header>
        <h1>Enjoy your products</h1>
        <h2>The purchase will be paid through the used credit card</h2>
      </header>
      <span className="content">
        <div className="image">
          <img
            className="logo"
            src={process.env.PUBLIC_URL + "/lekker/step-5.gif"}
            alt="lekkerland"
          />
        </div>
        <div
          style={{
            marginInline: "11%",
          }}
        >
          <div
            id="text-progress"
            style={{
              position: "relative",
              overflow: "hidden",
              display: "flex",
              borderRadius: "5px",
              backgroundColor: "white",
              color: "black",
              top: "-25%",
              marginBottom: "5%",
              width: "100%",
              justifyContent:"space-around"
            }}
          >
            {" "}
            <p>Purchase total</p>
            <p>{parsed.value || 0.0} €</p>
          </div>
        </div>
        <div style={{marginInline:"11%"}}>
          <a href="/de/home">
              <div id="myProgress" style={{bottom:"100px"}}>
                  <div id="text-progress">
                      Close
                  </div>
                  <div id="myBar"></div>
              </div>
          </a>
        </div>
      </span>
    </AppLayout>
  );
}
