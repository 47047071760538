
import React from "react";
import AppLayout from "./layout";
import { useHistory } from "react-router-dom";

export default function Idle() {
  const history = useHistory();

  return (
    <AppLayout title={""} style={{ padding: 0 }}>
      <header>
        <h1>Such dir deine Produkte aus</h1>
        <h2></h2>
      </header>
      <span class="content">
        <div class="image">
          <img
            class="logo"
            src={process.env.PUBLIC_URL + "/lekker/step-3.gif"}
            alt="lekkerland"
          />
        </div>
      </span>
    </AppLayout>
  );
}
