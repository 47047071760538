import React from "react";
import AppLayout from "./layout";
import { useHistory } from "react-router-dom";
import { set } from "ramda";

export default function Idle() {
  const history = useHistory();
  let [timer,setTimer] = React.useState(5);
  let [width,setWidth] = React.useState(0);

  React.useEffect(() => {
    console.log(timer);
    console.log(width);
    var temporizer = timer;

    const timeout = setTimeout(() => {
      temporizer--;
      if (temporizer > 0){
        setTimer(temporizer);
        width = width + 20;
        setWidth(width);
      }
    }, 1000);

    return () => clearTimeout(timeout);


  },[timer,width])

  return (
    <AppLayout title={""} style={{ padding: 0 }}>
      <header>
        <h1>Schliesse bitte die Türen</h1>
        <h2></h2>
      </header>
      <span className="content">
        <div className="image">
          <img
            className="logo"
            src={process.env.PUBLIC_URL + "/lekker/step-4.gif"}
            alt="lekkerland"
          />
        </div>
        <div style={{marginInline:"11%"}}>
            <h2 style={{marginBlock:"30px"}}>
                Türen werden verriegelt:
            </h2>
            <div id="myProgress">
                <div id="text-progress">
                <span id="timer">{timer}</span> Sek
                </div>
                <div id="myBar" style={{width:width+'%'}}></div>
                <div id="lockCircle">
                    <img src={process.env.PUBLIC_URL + "/lekker/lock.svg"} alt="lekkerland"/>
                </div>
            </div>
        </div>
      </span>
    </AppLayout>
  );
}
