import React from "react";
import AppLayout from "./layout";
import { useHistory } from "react-router-dom";

export default function Idle() {
  const history = useHistory();

  React.useEffect(() => {

    const timeout = setTimeout(() => {
      // 👇️ redirects to an external URL
      window.location.replace("/de/grab");
    }, 4000);

    return () => clearTimeout(timeout);
  })

  return (
    <AppLayout title={""} style={{ padding: 0 }}>
      <header>
        <h1>die Türen öffnen</h1>
        <h2>Warte auf das Klickgeräusch</h2>
      </header>
      <span class="content">
        <div class="image">
          <img
            class="logo"
            src={process.env.PUBLIC_URL + "/lekker/step-2.gif"}
            alt="lekkerland"
          />
        </div>
      </span>
    </AppLayout>
  );
}
