import React from "react";
import AppLayout from "./layout";
import { useHistory } from "react-router-dom";
import BuybyeService from "../services/buybye-service";

export default function Idle() {
  const history = useHistory();

  React.useEffect(() => {
    BuybyeService.setLanguage({ machine: { code: "0001" }, language: "de" });

    const timeout = setTimeout(() => {
      // 👇️ redirects to an external URL
      window.location.replace("/de/home");
    }, 15000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <AppLayout title={""} style={{ padding: 0 }}>
      <header>
        <h1>
          Halten Sie zum Entsperren ihre Kredit-/Debitkarte auf den Scanner
        </h1>
        <h2></h2>
      </header>
      <span className="content">
        <div className="image">
          <img
            className="logo"
            src={process.env.PUBLIC_URL + "/lekker/step-1.gif"}
            alt="lekkerland"
          />
        </div>

        <div className="bottom">
          <h2>Akzeptierte Zahlungen</h2>
          <div className="payments">
            <img src={process.env.PUBLIC_URL + "/lekker/visa.svg"} alt="visa" />
            <img
              src={process.env.PUBLIC_URL + "/lekker/mastercard.svg"}
              alt="mastercard"
            />
            <img
              src={process.env.PUBLIC_URL + "/lekker/googlePay.svg"}
              alt="googlePay"
            />
            <img
              src={process.env.PUBLIC_URL + "/lekker/applePay.svg"}
              alt="applePay"
            />
          </div>
        </div>
      </span>
    </AppLayout>
  );
}
