import React from "react";
import AppLayout from "./layout";
import { useHistory } from "react-router-dom";
import { set } from "ramda";

export default function Idle() {
  
  const history = useHistory();

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      // 👇️ redirects to an external URL
      window.location.replace("/de/home");
    }, 1500);

    return () => clearTimeout(timeout);
  }, []);
  
  return (
    <AppLayout title={""} style={{ padding: 0 }}>
      <header>
        <h1>Enjoy your products</h1>
        <h2>The purchase will be paid through the used credit card</h2>
      </header>
      <span className="content">
        <div className="image">
          <img
            className="logo"
            src={process.env.PUBLIC_URL + "/lekker/step-5.gif"}
            alt="lekkerland"
          />
        </div>
        <div style={{marginInline:"11%"}}>
          <a href="/de/home">
              <div id="myProgress" style={{bottom:"100px"}}>
                  <div id="text-progress">
                      Close
                  </div>
                  <div id="myBar"></div>
              </div>
          </a>
        </div>
      </span>
    </AppLayout>
  );
}
